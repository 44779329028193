const Resources = [
  {"name": "Выберите тип ресурса", "type": "", "id": 0},
  {"name": "Руда", "type": "First", "id": 1000072},
  {"name": "Камень", "type": "First", "id": 1000073},
  {"name": "Провиант", "type": "First", "id": 1000074},
  {"name": "Трава", "type": "First", "id": 1000071},
  {"name": "Древесина", "type": "First", "id": 1000076},
  {"name": "Рем. изделия", "type": "Second", "id": 1000069},
  {"name": "Золото", "type": "Gold", "id": -1}
] as const
export default Resources