const Limits = [
    [null, 6, 3, 1, 0, 0], // 0
    [null, null, 6, 3, 1, 0],
    [null, null, 10, 6, 3, 1],
    [null, null, null, 10, 6, 3],
    [null, null, null, 15, 10, 6],
    [null, null, null, null, 15, 10],
    [null, null, null, null, 21, 15],
    [null, null, null, null, null, 21] //7
]

export default Limits;